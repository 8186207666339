import axios from "axios";
import { createApp } from "vue";
import App from "./App.vue";
import environment from "./envirorment";
import router from "./router";
import "./style.scss";

const app = createApp(App);

axios.defaults.baseURL = environment.serverUrl;

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

app.use(router);
app.mount("#app");
