
import { defineComponent } from "vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import FAQ from "./components/FAQ.vue";

export default defineComponent({
	name: "App",
	components: {
		Header,
		Footer,
		FAQ,
	},
});
