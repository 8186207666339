import PresentationVue from "@/pages/HomeView.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LayoutView from "../views/layout/LayoutView.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "presentation",
		component: () => import("../pages/RevenueView.vue"),
	},
	{
		path: "/",
		name: "presentation",
		component: () => import("../pages/HomeView.vue"),
	},
	{
		path: "/reviews",
		name: "reviews",
		component: () => import("../pages/ReviewsView.vue"),
	},
	{
		path: "/francis",
		name: "francis",
		component: () => import("../pages/FrancisView.vue"),
	},
	{
		path: "/quality",
		name: "quality",
		component: () => import("../pages/QualityView.vue"),
	},
	{
		path: "/training",
		name: "training",
		component: () => import("../pages/TrainingView.vue"),
	},
	{
		path: "/revenue",
		name: "revenue",
		component: () => import("../pages/RevenueView.vue"),
	},
	{
		path: "/pricings",
		name: "pricings",
		component: () => import("../pages/PricingsView.vue"),
	},
	{
		path: "/:productId/:priceId",
		name: "signup",
		component: () => import("../pages/SignupView.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	linkActiveClass: "active",
	routes,
});

export default router;
